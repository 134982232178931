import { Outlet } from "@remix-run/react";
import { useState } from "react";

import { Footer } from "#app/components/layout/footer";
import { Header } from "#app/components/layout/header";
import { Sidebar } from "#app/components/layout/sidebar";

export default function AppLayout() {
	const [isOpen, setOpen] = useState(false);

	return (
		<div className="flex h-screen max-h-dvh flex-col">
			<Header isOpen={isOpen} setOpen={setOpen} />

			<div className="flex h-screen max-h-dvh overflow-hidden">
				<Sidebar isOpen={isOpen} setOpen={setOpen} />

				<div className="w-[calc(100%-16.5rem)] grow">
					<main className="flex h-full flex-1 flex-col overflow-y-auto">
						<div className="flex flex-1 flex-col">
							{/* renders the child inside _app+ folder */}
							<Outlet />
						</div>

						<Footer />
					</main>
				</div>
			</div>
		</div>
	);
}
